import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Link from 'components/ui/Link';

import {
    fetchAllFAQs,
    useNormalizedContentfulResponse,
} from 'services/contentful';

import cx from 'classnames';

import Text from 'components/ui/Text';
import Block from 'components/blocks/';
import ButtonCircle from 'components/ui/ButtonCircle';
import Grid from 'components/ui/Grid';
import SvgIcon from 'components/ui/SvgIcon';
import { useIntersection } from 'react-use';
import useScrollDirection from 'hooks/useScrollDirection';

import { Head } from 'components/globals/Head';

import FAQDetail from './FAQDetail';

import FAQPageJsonLd from 'utils/schema-dts/FAQPageJsonLd';

import styles from './FAQ.module.scss';

const FAQIndex = ({ currentCategory, faqs }) => {
    return (
        <>
            <Text
                className={styles.title}
                baseTheme="displaySmall"
                themes={{
                    large: 'displayMedium',
                }}
                as="h1"
            >
                {currentCategory.title}
            </Text>

            {faqs ? (
                faqs.map(faq => (
                    <Link.Entry
                        key={faq.slug}
                        className={styles.questionLink}
                        entry={{
                            ...faq,
                            category: currentCategory,
                        }}
                    >
                        <Text
                            as="div"
                            baseTheme="bodyMedium"
                            className={styles.question}
                        >
                            {faq.question}
                        </Text>
                        <div className={styles.questionIcon}>
                            <SvgIcon iconType="chevronRight" />
                        </div>
                    </Link.Entry>
                ))
            ) : (
                <p className={styles.questionsEmpty}>No Assigned FAQs</p>
            )}
        </>
    );
};

FAQIndex.propTypes = {
    currentCategory: PropTypes.object.isRequired,
    faqs: PropTypes.array.isRequired,
};

const FAQ = ({
    page: pageRequest,
    faqs: faqsRequest,
    categorySlug,
    faqSlug,
}) => {
    const ref = useRef(null);
    const refSticky = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isSticky, setIsSticky] = useState(false);
    const scrollDirection = useScrollDirection();

    const { title, template, seoTitle, metaDescription, shareImage } =
        useNormalizedContentfulResponse(pageRequest)[0];

    const faqs = useNormalizedContentfulResponse(faqsRequest);

    const currentCategory =
        template.categories.find(category => category.slug === categorySlug) ||
        template.categories[0];

    const currentFaq = faqs.find(faq => {
        return faq.slug === faqSlug;
    });

    const intersection = useIntersection(refSticky, {
        rootMargin: '0px',
        threshold: 1,
    });

    useEffect(() => {
        if (intersection && intersection.intersectionRatio < 1) {
            setIsSticky(true);
        } else {
            setIsSticky(false);
        }
    }, [intersection]);

    const handleOpenClick = () => {
        setIsOpen(true);
    };

    const handleCloseClick = () => {
        setIsOpen(false);
    };

    const handleOutsideClick = event => {
        if (ref?.current && !ref?.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        toggleEventListener('add');

        return () => {
            toggleEventListener('remove');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleEventListener = method => {
        document[`${method}EventListener`]('click', handleOutsideClick);
    };

    useEffect(() => {
        if (scrollDirection === 'down' || scrollDirection === 'up') {
            isOpen && setIsOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollDirection]);

    const isDetail = !!faqSlug;

    const currentCategoryFAQs =
        faqs?.filter(faq => faq.category?.slug === currentCategory.slug) || [];

    return (
        <div
            className={cx(styles.faqPage, {
                [styles['faqPage--isDetail']]: isDetail,
            })}
        >
            <Head
                seoTitle={seoTitle}
                shareTitle={seoTitle}
                metaDescription={metaDescription}
                shareDescription={metaDescription}
                shareImage={shareImage}
            >
                <title key="title">
                    {(currentCategory?.title &&
                        `${currentCategory?.title} - FAQ`) ||
                        title}
                </title>
                <script {...FAQPageJsonLd(isDetail ? [currentFaq] : faqs)} />
            </Head>
            <Block
                eyebrow={template.eyebrow}
                richHeadline={{
                    nodeType: 'document',
                    content: [
                        {
                            nodeType: 'paragraph',
                            content: [
                                {
                                    nodeType: 'text',
                                    value: `${template.headline}`,
                                    marks: [],
                                },
                            ],
                        },
                    ],
                }}
                theme="Masthead"
                contentTypeId="blockCallout"
            />
            <section className={styles.subNavPage}>
                <Grid>
                    <div className={styles.sideNavContainer}>
                        <nav
                            ref={refSticky}
                            className={cx(styles.sideNav, {
                                [styles.isOpen]: isOpen,
                                [styles.isSticky]: isSticky,
                                [styles.isFlushTop]: scrollDirection === 'down',
                            })}
                        >
                            <div className={styles.sideNavAbsInner}>
                                <div
                                    className={styles.sideNavAbsInnerBack}
                                    onClick={handleCloseClick}
                                />
                                <div
                                    className={styles.sideNavHeader}
                                    onClick={handleOpenClick}
                                >
                                    {currentCategory && (
                                        <Text
                                            as="span"
                                            baseTheme="labelLarge"
                                            className={styles.label}
                                        >
                                            {currentCategory.title}
                                        </Text>
                                    )}
                                    <ButtonCircle
                                        className={styles.openButton}
                                        onClick={handleCloseClick}
                                        theme="chevronDownCreamDark"
                                    />
                                </div>
                                <div className={styles.sideNavInner}>
                                    {template.categories.map((category, i) => {
                                        return (
                                            <div
                                                className={cx(
                                                    styles.sideNavGroup,
                                                    {
                                                        [styles.isActive]:
                                                            category.slug ===
                                                            currentCategory.slug,
                                                    }
                                                )}
                                                key={i}
                                            >
                                                <Text
                                                    as="span"
                                                    baseTheme="labelLarge"
                                                    className={styles.label}
                                                >
                                                    <Link.Entry
                                                        entry={category}
                                                    >
                                                        {category.title}
                                                    </Link.Entry>
                                                    {category.slug ===
                                                        currentCategory.slug && (
                                                        <ButtonCircle
                                                            className={
                                                                styles.navCaret
                                                            }
                                                            theme="chevronRightCreamDark"
                                                        />
                                                    )}
                                                </Text>
                                            </div>
                                        );
                                    })}
                                    <ButtonCircle
                                        className={styles.closeButton}
                                        onClick={handleCloseClick}
                                        theme="chevronDownCreamDark"
                                    />
                                </div>
                            </div>
                        </nav>
                    </div>
                    <section className={styles.content}>
                        {isDetail ? (
                            <FAQDetail {...currentFaq} />
                        ) : (
                            <FAQIndex
                                currentCategory={currentCategory}
                                faqs={currentCategoryFAQs}
                            />
                        )}
                    </section>
                </Grid>
            </section>
        </div>
    );
};

FAQ.getStaticTemplateProps = async (ctx, results) => {
    const faqs = await fetchAllFAQs();
    return { page: results, faqs };
};

FAQ.propTypes = {
    page: PropTypes.object.isRequired,
    categorySlug: PropTypes.string,
    faqSlug: PropTypes.string,
    faqs: PropTypes.object.isRequired,
    slug: PropTypes.string,
};

FAQ.defaultProps = {};

export default FAQ;
