import React from 'react';
import PropTypes from 'prop-types';
import NextHead from 'next/head';

export const Head = ({
    seoTitle,
    metaDescription,
    shareTitle,
    shareDescription,
    shareImage,
    children,
}) => {
    const shareImageURL = shareImage?.file?.url?.startsWith('//')
        ? `https:${shareImage.file.url}`
        : shareImage;

    return (
        <NextHead>
            <title key="title">{seoTitle} - Tillamook</title>
            {metaDescription && (
                <meta
                    key="description"
                    name="description"
                    content={metaDescription}
                />
            )}
            {shareTitle && (
                <>
                    <meta
                        key="og:title"
                        property="og:title"
                        content={shareTitle}
                    />
                    <meta
                        key="twitter:title"
                        name="twitter:title"
                        content={shareTitle}
                    />
                </>
            )}
            {shareDescription && (
                <>
                    <meta
                        key="og:description"
                        property="og:description"
                        content={shareDescription}
                    />
                    <meta
                        key="twitter:description"
                        name="twitter:description"
                        content={shareDescription}
                    />
                </>
            )}
            {shareImageURL && (
                <>
                    <meta
                        key="og:image"
                        property="og:image"
                        content={`${shareImageURL}?w=1200&fm=jpg&q=80`}
                    />
                    <meta
                        key="twitter:image"
                        name="twitter:image"
                        content={`${shareImageURL}?w=1200&fm=jpg&q=80`}
                    />
                </>
            )}
            {children}
        </NextHead>
    );
};

Head.propTypes = {
    seoTitle: PropTypes.string,
    metaDescription: PropTypes.string,
    shareImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    shareTitle: PropTypes.string,
    shareDescription: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};
