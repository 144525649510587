import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import sanitizeHtml from 'sanitize-html';

import { cleanupContentfulData } from 'utils/contentful-resolve-response';
import { getEntryLink } from 'components/ui/Link/EntryLink';

export const SEPARATOR = '|*SEP*|';

const entryToHref = entry => getEntryLink(cleanupContentfulData(entry))?.as;

const nodeToString = node => {
    return `<a href="https://tillamook.com${entryToHref(node.data.target)}">${
        node.content[0].value
    }</a>`;
};

const removeHtml = string => {
    return sanitizeHtml(string, { allowedTags: [] })?.trim();
};

const renderOptions = {
    renderNode: {
        [INLINES.ENTRY_HYPERLINK]: nodeToString,
        [INLINES.HYPERLINK]: node => node.data.uri,
        [INLINES.ASSET_HYPERLINK]: node => node.data.uri,
        [BLOCKS.PARAGRAPH]: (node, next) => next(node.content),
        [BLOCKS.UL_LIST]: (node, next) => next(node.content),
        [BLOCKS.LIST_ITEM]: (node, next) =>
            `${removeHtml(next(node.content))}${SEPARATOR}`,
    },
};

export const richTextToHtml = richText => {
    return documentToHtmlString(richText, renderOptions);
};

export const organization = {
    '@type': 'Organization',
    name: 'Tillamook',
    logo: {
        '@type': 'imageObject',
        url: 'https://tillamook.com/images/icon.png',
    },
};

export const fromContentfulImage = image => {
    const imageUrl = image?.file?.url;
    return {
        '@type': 'imageObject',
        url: imageUrl && `https:${imageUrl}`,
        height: image?.file?.details?.image?.height,
        width: image?.file?.details?.image?.width,
    };
};
