import React from 'react';
import { Head } from 'components/globals/Head';
import PropTypes from 'prop-types';

import Text from 'components/ui/Text';
import SvgIcon from 'components/ui/SvgIcon';
import Link from 'components/ui/Link';
import Button from 'components/ui/Button';
import RichText from 'components/ui/RichText';

import styles from './FAQDetail.module.scss';

const FAQDetail = ({
    question,
    answer,
    description,
    category,
    seoTitle,
    shareTitle,
    shareDescription,
    metaDescription,
}) => {
    const defaultTitle = `${category.title} - ${question} - FAQ`;

    return (
        <article className={styles.detail}>
            <Head
                seoTitle={seoTitle || defaultTitle}
                shareTitle={shareTitle || defaultTitle}
                shareDescription={shareDescription || description}
                metaDescription={metaDescription || description}
            />
            <Link.Entry
                entry={category}
                className={styles['breadcrumbs__currentCategory']}
            >
                <span className={styles['breadcrumbs__carrot']}>
                    <SvgIcon iconType={'carrotLeft'} />
                </span>
                <Text
                    className={styles.title}
                    baseTheme="labelSmall"
                    themes={{
                        large: 'labelLarge',
                    }}
                >
                    Return to {category.title}
                </Text>
            </Link.Entry>
            <Text
                className={styles.title}
                baseTheme="displayXSmall"
                themes={{
                    large: 'displayMedium',
                }}
                as="h1"
            >
                {question}
            </Text>
            <RichText richText={answer} />
            <div className={styles.endCard}>
                <Text
                    baseTheme="headingXSmall"
                    themes={{
                        large: 'headingSmall',
                    }}
                    as="h3"
                >
                    Didn’t get the answer you were looking for?
                </Text>
                <Button href="/contact">Contact Us</Button>
            </div>
        </article>
    );
};

FAQDetail.propTypes = {
    answer: PropTypes.object,
    blocks: PropTypes.array,
    category: PropTypes.object,
    description: PropTypes.string,
    question: PropTypes.string,
    seoTitle: PropTypes.string,
    shareTitle: PropTypes.string,
    metaDescription: PropTypes.string,
    shareDescription: PropTypes.string,
};

FAQDetail.defaultProps = {
    blocks: [],
};

export default FAQDetail;
